import { FC } from 'react';
import { Headline } from '_atoms';
import classnames from 'classnames';
import { PageLayoutProps } from './DefaultPageLayout';

const headerClassName = classnames(
    'container-fluid p-9 xl:max-w-max-content relative print:px-0 print:py-4',
);

export const ImmersivePageLayout: FC<PageLayoutProps> = (props) => {
    const { title, subtitle, headerSubActions, className, children } = props;

    return (
        <div
            className={classnames(
                'p-0 relative print:py-4 h-content-height flex flex-col overflow-hidden',
                className,
            )}
        >
            <div className={classnames(headerClassName, 'shrink-0 grow-0')}>
                <Headline Level="h3">{title}</Headline>
                {subtitle ? (
                    <span className="text-base text-neutral-500">
                        {subtitle}
                    </span>
                ) : null}
                {headerSubActions}
            </div>
            <div className="grow overflow-y-auto">{children}</div>
        </div>
    );
};
