import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
    CaseData,
    CompanyData,
    ReportStep,
    Target,
    TargetStatus,
} from '../../../services/dataService';
import { getAccessToken } from '../../../services/authenticationService';
import {
    SubNavigationBar,
    SubNavigationBarProps,
} from '../../../components/Layout/SubNavigationBar';
import {
    generatePath,
    Redirect,
    Switch,
    useHistory,
    useRouteMatch,
} from 'react-router-dom';
import { ProtectedRoute } from '../../../Router';
import {
    CountrySelectOption,
    getCountrySelectionOptions,
} from '../../targets/TargetNew/countryCodeOptions';
import { resultsRoutes } from '../../../routes';
import { backendBaseUrl } from '../../../backendConfig.json';
import { useCaseQuery, useCustomerQuery } from '_queries';
import {
    Button,
    LoadingSpinner,
    TooltipContentV2,
    TooltipTriggerV2,
    TooltipV2,
} from '_atoms';
import { PageNotFound } from '_organisms';
import { getCdnUrl, isCustomerExpired, withinSpanOfDays } from '_utils';
import { RedFlagItem } from 'src/types';
import { useUserState } from '../../../context/User';
import { map, zipObject } from 'lodash';
import { TargetReportAttribute, WocoEntity } from '@indicium/common';
import { useCaseKeywordsQuery } from '../../../hooks/queries/useCaseQuery';
import { useResultsQuery } from '../../../hooks/queries/useResultsQuery';
import {
    EntityIndicator,
    useTargetIndicatorsQuery,
} from '../../../hooks/queries/useTargetIndicatorsQuery';
import { TbLoader3 } from 'react-icons/tb';
import classNames from 'classnames';
import { useMenuContext } from '../../../context/Menu';
import { FaPrint } from 'react-icons/fa6';

export const getRedFlags = (
    { companies, profile }: Target,
    countrySelectionOptions: CountrySelectOption[],
    {
        caseId,
        targetId,
        accessToken,
    }: Record<'caseId' | 'targetId' | 'accessToken', string>,
): RedFlagItem[] => {
    const countriesMap = zipObject(
        map(countrySelectionOptions, 'code'),
        map(countrySelectionOptions, 'name'),
    );

    const transformWocoEntityToRedFlagItem = (
        {
            value: {
                country: countryCode,
                positionOrCas,
                category,
                subCategory,
                name,
                externalId,
            },
            sources,
        }: TargetReportAttribute<WocoEntity>,
        apiIds: TargetReportAttribute<string>[],
        roles?: CompanyData['data']['role'],
    ): RedFlagItem => {
        const country = countryCode ? countriesMap[countryCode] : undefined;
        const type = `${category}/${subCategory}`;
        const reportUrl = `https://${backendBaseUrl}/redflagpdfproxy/${caseId}/${targetId}/${externalId}?token=${accessToken}`;

        return {
            apiId:
                apiIds.find((id) => id.sources.includes('orbis'))?.value || '',
            description: positionOrCas || '',
            name,
            type,
            sources,
            country,
            reportUrl,
            roles,
        };
    };

    const redFlagsFromCompanies = (companies || []).flatMap(
        ({ data: { wocoEntities, apiIds, role } }) => {
            return (wocoEntities || []).map((entity) =>
                transformWocoEntityToRedFlagItem(entity, apiIds, role),
            );
        },
    );

    const redFlagsFromProfiles = (profile?.data?.wocoEntities || []).map(
        (entity) =>
            transformWocoEntityToRedFlagItem(
                entity,
                profile?.data?.apiIds || [],
            ),
    );

    return [...redFlagsFromProfiles, ...redFlagsFromCompanies];
};

export type ResultsProps = {
    accessToken: string;
    caseData: CaseData;
    redFlags: RedFlagItem[];
    entityIndicators: EntityIndicator[];
    targetData?: Target;
    targetLoading: boolean;
    targetError: boolean;
    targetImages: string[];
};

const getTestIdFromName = (name: string) => {
    switch (name) {
        case 'summary':
            return 'analysis menu summary';
        case 'overview':
            return 'analysis menu overview';
        case 'personalData':
            return 'analysis menu personal data';
        case 'overviewAnalysis':
            return 'analysis menu analysis';
        case 'riskAnalysis.title':
            return 'analysis menu risk analysis';
        case 'networkGraph.title':
            return 'analysis menu network graph';
        case 'cvData.title':
            return 'analysis menu resume data';
        case 'geoLocations.title':
            return 'analysis menu notes on locations';
        case 'persons.title':
            return 'analysis menu persons';
        case 'rawData.title':
            return 'analysis menu raw data';
        case 'rawData.search':
            return 'analysis menu free text search';
        case 'rawData.socialMedia':
            return 'analysis menu social media';
        case 'rawData.press':
            return 'analysis menu press';
        case 'rawData.registerData':
            return 'analysis menu register data';
        case 'rawData.searchEngines':
            return 'analysis menu search engines';
        case 'rawData.imageSlashVideos':
            return 'analysis menu images videos';
        default:
            console.warn('Unknown dataTestId name: ', name);
            return undefined;
    }
};

const areStepsFetchingDetails = (
    steps: ReadonlyArray<ReportStep>,
    stepMetadata: ReadonlyArray<{
        step: ReportStep;
        status: TargetStatus;
    }>,
): boolean => {
    const entireWorkflowStatus =
        stepMetadata.find(({ step }) => step === 'entire_workflow')?.status ??
        null;

    if (
        entireWorkflowStatus === TargetStatus.Completed ||
        TargetStatus.CompletedWithIrregularities
    ) {
        return false;
    }

    return steps
        .map(
            (step) =>
                stepMetadata.find((sm) => sm.step === step)?.status ?? null,
        )
        .some((status) => status === TargetStatus.FetchingDetails);
};

export const Results: FC = () => {
    const { t, i18n } = useTranslation();
    const { caseId, targetId } =
        useParams<{
            caseId: string;
            targetId: string;
        }>();
    const history = useHistory();

    const handlePrint = useCallback(() => {
        history.push(
            generatePath(resultsRoutes.printVersion.path, {
                targetId,
                caseId,
            }),
        );
    }, [history, targetId, caseId]);

    const [{ customerId }] = useUserState();
    const { data: customer } = useCustomerQuery(customerId, {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });
    const { collapsed } = useMenuContext();

    const [accessToken, setAccessToken] = useState<string>();

    const { data: caseData, ...caseQuery } = useCaseQuery(caseId);
    const { data: caseKeywords, ...caseKeywordsQuery } =
        useCaseKeywordsQuery(caseId);
    const { data: targetData, ...targetQuery } = useResultsQuery(
        caseId,
        targetId,
    );
    const targetIndicatorsQuery = useTargetIndicatorsQuery(targetId);

    const entityIndicators = targetIndicatorsQuery.isSuccess
        ? targetIndicatorsQuery.data
        : [];

    useEffect(() => {
        (async () => {
            setAccessToken(await getAccessToken());
        })();
    }, []);

    useEffect(() => {
        const listener = (e: KeyboardEvent) => {
            // detect control + p
            if (e.ctrlKey && e.key === 'p') {
                e.preventDefault();
                e.stopPropagation();
                handlePrint();
            }
        };
        document.addEventListener('keydown', listener);
        return () => {
            document.removeEventListener('keydown', listener);
        };
    }, [handlePrint]);

    const isPrint = useRouteMatch(resultsRoutes.printVersion.path);

    const countrySelectionOptions = getCountrySelectionOptions(i18n.language);

    const redFlags = useMemo(
        () =>
            targetData && accessToken
                ? getRedFlags(targetData, countrySelectionOptions, {
                      caseId,
                      targetId,
                      accessToken,
                  })
                : [],
        [targetData, countrySelectionOptions, caseId, targetId, accessToken],
    );

    const isRawDataDisabled =
        process.env.REACT_APP_STAGE === 'production' &&
        ((!!targetData?.updatedAt && !withinSpanOfDays(targetData.updatedAt)) ||
            isCustomerExpired(customer?.expiresAt));

    const getNavigationItemIcon = (
        stepFetchingDetails: boolean,
    ): JSX.Element | null => {
        if (!stepFetchingDetails) {
            return null;
        }

        return (
            <TbLoader3 className="text-yellow-500 animate-spin-slow ml-1 w-4 h-4" />
        );
    };

    const getMenuItemProps = useCallback(
        (
            key: ReadonlyArray<ReportStep>,
            statuses: ReadonlyArray<{
                step: ReportStep;
                status: TargetStatus;
            }>,
        ): {
            disabled: boolean;
            disabledTooltip: string;
            icon: JSX.Element | null;
        } => {
            const stepsFetchingDetails = areStepsFetchingDetails(key, statuses);

            return {
                disabled: isRawDataDisabled || stepsFetchingDetails,
                disabledTooltip: t('rawData.dataNotAvailableTooltip'),
                icon: getNavigationItemIcon(stepsFetchingDetails),
            };
        },
        [isRawDataDisabled, t],
    );

    const rawDataGroupFetchingDetails = [
        'google_profile' as const,
        'press' as const,
        'network' as const,
        'socialmedia' as const,
    ]
        .map((step) =>
            areStepsFetchingDetails([step], targetData?.stepStatuses ?? []),
        )
        .every((stepFetchingDetails) => stepFetchingDetails);

    const handleSubNavigationItems = useMemo((): SubNavigationBarProps => {
        const subNavigationItems: SubNavigationBarProps = {
            caseData: { title: caseData?.title || '', caseId },
            title: targetData
                ? [targetData.firstname, targetData.lastname].join(' ')
                : '',
            navigationItems: [
                {
                    name: t('overview'),
                    dataTestId: getTestIdFromName('overview'),
                    href: generatePath(resultsRoutes.dashboardOverview.path, {
                        caseId,
                        targetId,
                    }),
                },
                {
                    name: t('personalData'),
                    dataTestId: getTestIdFromName('personalData'),
                    href: generatePath(resultsRoutes.dashboardProfile.path, {
                        caseId,
                        targetId,
                    }),
                },
                {
                    name: t('overviewAnalysis'),
                    dataTestId: getTestIdFromName('overviewAnalysis'),
                    children: [
                        {
                            name: t('riskAnalysis.title'),
                            dataTestId: getTestIdFromName('riskAnalysis.title'),
                            href: generatePath(
                                resultsRoutes.riskAnalysis.path,
                                {
                                    caseId,
                                    targetId,
                                },
                            ),
                        },
                        {
                            name: t('networkGraph.title'),
                            dataTestId: getTestIdFromName('networkGraph.title'),
                            href: generatePath(
                                resultsRoutes.networkGraphV2.path,
                                {
                                    caseId,
                                    targetId,
                                },
                            ),
                            icon: getNavigationItemIcon(
                                areStepsFetchingDetails(
                                    ['network'],
                                    targetData?.stepStatuses ?? [],
                                ),
                            ),
                        },
                        {
                            name: t('cvData.title'),
                            dataTestId: getTestIdFromName('cvData.title'),
                            href: generatePath(resultsRoutes.cV.path, {
                                caseId,
                                targetId,
                            }),
                            icon: getNavigationItemIcon(
                                areStepsFetchingDetails(
                                    ['network', 'socialmedia'],
                                    targetData?.stepStatuses ?? [],
                                ),
                            ),
                        },
                        // {
                        //   name: t('geoLocations.title'),
                        //   dataTestId: getTestIdFromName('geoLocations.title'),
                        //   href: '#',
                        // },
                        // {
                        //   name: t('persons.title'),
                        //   dataTestId: getTestIdFromName('persons.title'),
                        //   href: '#',
                        // },
                    ],
                },
                {
                    name: t('rawData.title'),
                    dataTestId: getTestIdFromName('rawData.title'),
                    disabled: isRawDataDisabled || rawDataGroupFetchingDetails,
                    disabledTooltip: isRawDataDisabled
                        ? t('rawData.disabledTooltip')
                        : t('rawData.dataNotAvailableTooltip'),
                    icon:
                        rawDataGroupFetchingDetails && !isRawDataDisabled
                            ? getNavigationItemIcon(rawDataGroupFetchingDetails)
                            : undefined,
                    children: [
                        {
                            name: t('rawData.search'),
                            dataTestId: getTestIdFromName('rawData.search'),
                            href: generatePath(
                                resultsRoutes.dashboardAllDataSearch.path,
                                {
                                    caseId,
                                    targetId,
                                },
                            ),
                            ...getMenuItemProps(
                                ['google_profile', 'socialmedia'],
                                targetData?.stepStatuses ?? [],
                            ),
                        },
                        {
                            name: t('rawData.socialMedia'),
                            dataTestId: getTestIdFromName(
                                'rawData.socialMedia',
                            ),
                            href: generatePath(
                                resultsRoutes.dashboardSocialMedia.path,
                                {
                                    caseId,
                                    targetId,
                                },
                            ),
                            ...getMenuItemProps(
                                ['socialmedia'],
                                targetData?.stepStatuses ?? [],
                            ),
                        },
                        {
                            name: t('rawData.press'),
                            dataTestId: getTestIdFromName('rawData.press'),
                            href: generatePath(
                                resultsRoutes.dashboardPress.path,
                                {
                                    caseId,
                                    targetId,
                                },
                            ),
                            ...getMenuItemProps(
                                ['press'],
                                targetData?.stepStatuses ?? [],
                            ),
                        },
                        {
                            name: t('rawData.registerData'),
                            dataTestId: getTestIdFromName(
                                'rawData.registerData',
                            ),
                            href: generatePath(
                                resultsRoutes.registerData.path,
                                {
                                    caseId,
                                    targetId,
                                },
                            ),
                            ...getMenuItemProps(
                                ['network'],
                                targetData?.stepStatuses ?? [],
                            ),
                        },
                        {
                            name: t('rawData.searchEngines'),
                            dataTestId: getTestIdFromName(
                                'rawData.searchEngines',
                            ),
                            href: generatePath(
                                resultsRoutes.searchEngines.path,
                                {
                                    caseId,
                                    targetId,
                                },
                            ),
                            ...getMenuItemProps(
                                ['google_profile'],
                                targetData?.stepStatuses ?? [],
                            ),
                        },
                        {
                            name: t('rawData.imageSlashVideos'),
                            dataTestId: getTestIdFromName(
                                'rawData.imageSlashVideos',
                            ),
                            href: generatePath(resultsRoutes.images.path, {
                                caseId,
                                targetId,
                            }),
                            ...getMenuItemProps(
                                ['google_profile', 'socialmedia'],
                                targetData?.stepStatuses ?? [],
                            ),
                        },
                    ],
                },
                {
                    name: t('inputDataAndSources'),
                    children: [
                        {
                            name: t('caseData'),
                            href: generatePath(
                                resultsRoutes.dashboardCaseData.path,
                                {
                                    caseId,
                                    targetId,
                                },
                            ),
                        },
                        {
                            name: t('inputData'),
                            href: generatePath(
                                resultsRoutes.dashboardInput.path,
                                {
                                    caseId,
                                    targetId,
                                },
                            ),
                        },
                        {
                            name: t('targetPersonSelection'),
                            href: generatePath(
                                resultsRoutes.dashboardCandidates.path,
                                {
                                    caseId,
                                    targetId,
                                },
                            ),
                        },
                        // {
                        //   name: t('sourceOverview'),
                        //   disabled: true,
                        // },
                    ],
                },
            ],
            downloadItems: [
                // {
                //   name: 'PDF',
                //   href: '#',
                // },
                // {
                //   name: 'XLS',
                //   href: '#',
                // },
            ],
        };

        return subNavigationItems;
    }, [
        caseData?.title,
        caseId,
        targetData,
        t,
        targetId,
        isRawDataDisabled,
        rawDataGroupFetchingDetails,
        getMenuItemProps,
    ]);

    if (caseQuery.isError || caseKeywordsQuery.isError || targetQuery.isError) {
        return <PageNotFound />;
    }

    if (
        caseQuery.isLoading ||
        caseKeywordsQuery.isLoading ||
        targetQuery.isLoading
    ) {
        return (
            <div className="mt-20">
                <LoadingSpinner message={t('profileLoading')} />
            </div>
        );
    }

    const profileImages = (targetData?.profileImages || [])
        .sort(
            (a, b) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime(),
        )
        .map((image) => getCdnUrl(image.url));

    return (
        <>
            <SubNavigationBar
                {...handleSubNavigationItems}
                targetData={targetData}
            />
            <div
                className={classNames(
                    // we can get rid of the max-width when we axe the current lib used for the CV page
                    'relative',
                    !collapsed
                        ? 'max-w-result-page ml-50'
                        : 'max-w-result-page-collapsed-sidebar print:max-w-full',
                )}
            >
                {!isPrint && (
                    <div className="print:hidden absolute top-2 right-4 z-5">
                        <TooltipV2>
                            <TooltipContentV2>
                                {t('printTooltip')}
                            </TooltipContentV2>
                            <TooltipTriggerV2>
                                <Button
                                    icon={FaPrint}
                                    className="print:hidden"
                                    onClick={handlePrint}
                                />
                            </TooltipTriggerV2>
                        </TooltipV2>
                    </div>
                )}
                <Switch>
                    <Redirect
                        to={resultsRoutes.dashboardOverview.path}
                        path="/cases/:caseId/targets/:targetId/results"
                        exact={true}
                    />
                    {Object.values(resultsRoutes)
                        .sort((a, b) => b.weight - a.weight)
                        .map((route, index) => (
                            <ProtectedRoute
                                key={index}
                                {...route}
                                props={{
                                    accessToken,
                                    targetData,
                                    targetImages: profileImages,
                                    caseData: {
                                        ...caseData,
                                        keywords: caseKeywords,
                                    },
                                    redFlags,
                                    entityIndicators,
                                    disabled: isRawDataDisabled,
                                }}
                            />
                        ))}
                </Switch>
            </div>
        </>
    );
};
