import React, {
    DetailedHTMLProps,
    forwardRef,
    InputHTMLAttributes,
} from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

interface WizardFormInputProps
    extends DetailedHTMLProps<
        InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > {
    label?: string;
    errorMsg?: string;
    hintMsg?: string;
}

export const WizardInput = forwardRef<HTMLInputElement, WizardFormInputProps>(
    (props, ref) => {
        const { t } = useTranslation();
        const {
            className,
            label,
            errorMsg,
            required,
            children,
            hintMsg,
            placeholder,
            ...rest
        } = props;

        const input = (
            <input
                className={classnames(
                    className,
                    'bg-neutral-50 px-4 py-3.5 text-base focus:ring-0 border-neutral-400 focus:border-primary-4 hover:border-primary-4 block w-full rounded-md transition-all border-2',
                    rest.disabled && 'opacity-50',
                )}
                ref={ref}
                placeholder={placeholder ? t(placeholder) : undefined}
                {...rest}
            />
        );

        return (
            <div className="w-full max-w-flow-text-base mx-auto relative">
                {label ? (
                    <label>
                        <span className="block text-sm font-bold font-jost text-neutral-500">
                            {t(label)}
                            {required && (
                                <span className="text-error-1"> *</span>
                            )}
                        </span>
                        {input}
                    </label>
                ) : (
                    input
                )}
                {children}
                {hintMsg && (
                    <span className="text-neutral-500 block text-sm">
                        {t(hintMsg)}
                    </span>
                )}
                {errorMsg && (
                    <div className="text-error-1 text-sm">{t(errorMsg)}</div>
                )}
            </div>
        );
    },
);

WizardInput.displayName = 'WizardInput';
