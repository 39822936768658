import { CoCaEntity, LinkedEntity, Person } from '../../types';
import React from 'react';
import { Memberships } from './Memberships';
import { LinkedEntityListing } from '../LinkedEntityListing';

export const PersonComplianceFlags: React.FC<{
    entity: Person;
    onEntityClick?: (id: string) => void;
}> = ({ entity, onEntityClick }) => {
    if (!entity.coca_entities || entity.coca_entities.length === 0) {
        return null;
    }

    const linkedEntities: LinkedEntity[] = entity.coca_entities.map(
        (cocaEntity: CoCaEntity) => [
            cocaEntity.external_id,
            {
                name: [
                    cocaEntity.category,
                    [
                        typeof cocaEntity.sub_category === 'object'
                            ? cocaEntity.sub_category.value
                            : cocaEntity.sub_category,
                    ]
                        .filter((x) => !!x)
                        .map((x) => `(${x})`),
                ].join(' '),
                items: [
                    {
                        id: cocaEntity.external_id,
                        title: cocaEntity.entity_type,
                        details: [
                            {
                                label: 'External ID',
                                value: cocaEntity.external_id,
                            },
                            { label: 'Country', value: cocaEntity.country },
                            {
                                label: 'Position or CAS',
                                value: cocaEntity.position_or_cas,
                            },
                            {
                                label: 'Sanction List Source',
                                value: cocaEntity.sanctionlist_source,
                            },
                            {
                                label: 'Sanction List Country',
                                value: cocaEntity.sanctionlist_country_iso_code,
                            },
                            {
                                label: 'AKA Names',
                                value: cocaEntity.aka_names
                                    ? cocaEntity.aka_names.join(', ')
                                    : '',
                            },
                            {
                                label: 'Entity Level',
                                value: cocaEntity.entity_level,
                            },
                            {
                                label: 'Entity Date',
                                value: cocaEntity.entity_date,
                            },
                        ],
                    },
                    ...(cocaEntity.individual
                        ? [
                              {
                                  id: `${cocaEntity.external_id}-individual`,
                                  title: 'Individual Details',
                                  details: [
                                      !!cocaEntity.individual.primary_name && {
                                          label: 'Primary Name',
                                          value: cocaEntity.individual
                                              .primary_name,
                                      },
                                      !!cocaEntity.individual.function && {
                                          label: 'Function',
                                          value: cocaEntity.individual.function,
                                      },
                                      !!cocaEntity.individual.birthdate && {
                                          label: 'Birth Date',
                                          value: cocaEntity.individual.birthdate.join(
                                              ', ',
                                          ),
                                      },
                                      !!cocaEntity.individual.birthplace && {
                                          label: 'Birth Place',
                                          value: cocaEntity.individual
                                              .birthplace,
                                      },
                                      {
                                          label: 'Gender',
                                          value: cocaEntity.individual.gender,
                                      },
                                  ].filter(
                                      (x) => !!x,
                                  ) as LinkedEntity[1]['items'][0]['details'],
                              },
                          ]
                        : []),
                    ...(cocaEntity.addresses && cocaEntity.addresses.length > 0
                        ? [
                              {
                                  id: `${cocaEntity.external_id}-addresses`,
                                  title: 'Addresses',
                                  details: cocaEntity.addresses.map(
                                      (address, index) => ({
                                          label: `${index + 1}`,
                                          value: `${address.address}, ${address.city}, ${address.country_label}`,
                                      }),
                                  ),
                              },
                          ]
                        : []),
                    /*...(cocaEntity.web_sources && cocaEntity.web_sources.length > 0 ? [{
                    id: `${cocaEntity.external_id}-web-sources`,
                    title: 'Web Sources',
                    details: cocaEntity.web_sources.map((source) => ({
                        label: source.source_id,
                        value: source.url,
                    })),
                }] : []),*/
                ],
                flags: [{ displayText: cocaEntity.entity_type }],
                markInactive: false,
            },
        ],
    );

    return (
        <LinkedEntityListing
            label="Compliance Flags"
            linkedEntities={linkedEntities}
            onEntityClick={onEntityClick}
            formatDate={(dateString) =>
                new Date(dateString).toLocaleDateString()
            }
        />
    );
};
export const DateOfBirth: React.FC<{
    entity: Person;
    renderField: (label: string, value: string | number) => JSX.Element;
}> = ({ entity, renderField }) => {
    if (!entity.birth_date) {
        return null;
    }

    const birthDate = new Date(entity.birth_date);
    const age = new Date().getFullYear() - birthDate.getFullYear();

    const formattedDate = birthDate.toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
    return renderField('Date of Birth', `${formattedDate} (${age} years)`);
};

export const Gender: React.FC<{
    entity: Person;
    renderField: (label: string, value: string | number) => JSX.Element;
}> = ({ entity, renderField }) => {
    if (!entity.gender) {
        return null;
    }

    const genderMap: { [key: string]: string } = {
        F: 'Female',
        M: 'Male',
        '<other>': 'Other (X)',
    };

    return renderField('Gender', genderMap[entity.gender] || entity.gender);
};

export const PersonDetails: React.FC<{
    entity: Person;
    renderField: (
        label: string,
        value: string | number,
        key?: string,
    ) => JSX.Element;
    onEntityClick: (id: string) => void;
    renderHeader: (title: string) => JSX.Element;
}> = ({ entity, renderField, onEntityClick, renderHeader }) => {
    const fields: [string, keyof Person][] = [
        ['Place of Birth', 'place_of_birth'],
        ['Home City', 'home_city'],
        ['Home Country', 'home_country'],
        ['Home Email', 'home_email'],
        ['Multiple Nationalities', 'multiple_nationalities_label'],
    ];

    const displayName = entity.full_name?.replace(/^(Ms |Mr )/, '') ?? '';

    return (
        <>
            {renderHeader(displayName)}
            <DateOfBirth entity={entity} renderField={renderField} />
            <Gender entity={entity} renderField={renderField} />
            {fields.map(
                ([label, key]) =>
                    entity[key] &&
                    renderField(label, entity[key] as string | number, key),
            )}
            <PersonComplianceFlags
                entity={entity}
                onEntityClick={onEntityClick}
            />
            <Memberships entity={entity} onEntityClick={onEntityClick} />
        </>
    );
};
