import { ElementType, FC, Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { MdOutlineLogout } from 'react-icons/md';
import { HiOutlineX } from 'react-icons/hi';
import classnames from 'classnames';
import { ReactComponent as Logo } from '../../images/logo.svg';
import { ReactComponent as ActiveIndicator } from '../../images/navbar-active-indicator.svg';
import { useLocation } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { signout } from '../../services/authenticationService';
import { SubNavigationBar, SubNavigationBarProps } from './SubNavigationBar';
import { UserActionTypes, useUserState } from '../../context/User';
import { SpotlightTarget } from '@atlaskit/onboarding';

export type Navigation = Readonly<
    {
        id: string;
        name: string;
        href: string;
        icon: ElementType;
        condition?: boolean;
        subNavigation?: SubNavigationBarProps;
        disabled?: boolean;
        spotlightTarget?: string;
    }[]
>;

export type NarrowSidebarProps = {
    navigationItems: Navigation;
};

export const LeftNavigationBar: FC<NarrowSidebarProps> = ({
    navigationItems,
}: NarrowSidebarProps) => {
    const { pathname } = useLocation();
    const [{ initialRoute }] = useUserState();

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [subNavigation, setSubNavigation] = useState<SubNavigationBarProps>();

    const [_, dispatch] = useUserState();

    const handleSignOut = async () => {
        await signout();
        dispatch({
            type: UserActionTypes.SET,
            user: null,
        });
    };

    const activeIndex = navigationItems.findIndex((item) =>
        pathname.replaceAll('/', '_').startsWith(item.id),
    );

    return (
        <Fragment>
            <div className="relative w-25 pr-5 bg-primary-1 flex flex-col justify-between after:w-5 after:h-full after:absolute after:top-0 after:right-0 after:bg-primary-5 print:hidden">
                <div className="w-full flex flex-col items-center">
                    <div className="flex-shrink-0 p-5 flex items-center text-primary-4 fill-current">
                        <Link to={initialRoute}>
                            <SpotlightTarget name="onboardingLeftMenuLogo">
                                <Logo
                                    width="40"
                                    height="40"
                                    className="text-primary-4 fill-current"
                                />
                            </SpotlightTarget>
                        </Link>
                    </div>
                    <div className="flex-1 mt-2 w-full px-3 flex flex-col items-center overflow-visible">
                        {navigationItems.map(
                            ({
                                name,
                                href,
                                icon: Icon,
                                id,
                                disabled,
                                subNavigation,
                                spotlightTarget,
                            }) => {
                                const isActive = pathname
                                    .replaceAll('/', '_')
                                    .startsWith(id);

                                if (isActive && subNavigation) {
                                    setSubNavigation(subNavigation);
                                }

                                return (
                                    <Link
                                        key={name}
                                        to={href}
                                        className={classnames(
                                            'fill-current group h-12 w-12 bg-primary-1 rounded-full flex flex-col justify-center items-center text-xs font-medium hover:text-neutral-100 transition-all z-2 transform-gpu',
                                            isActive
                                                ? 'text-neutral-100 translate-x-5 my-3'
                                                : 'text-neutral-400 translate-x-0 my-1',
                                            disabled &&
                                                'opacity-50 cursor-not-allowed',
                                        )}
                                        aria-current={
                                            isActive ? 'page' : undefined
                                        }
                                        onClick={(event) =>
                                            disabled && event.preventDefault()
                                        }
                                    >
                                        {spotlightTarget ? (
                                            <SpotlightTarget
                                                name={spotlightTarget}
                                            >
                                                <Icon
                                                    className="text-inherit h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                            </SpotlightTarget>
                                        ) : (
                                            <Icon
                                                className="text-inherit h-6 w-6"
                                                aria-hidden="true"
                                            />
                                        )}
                                    </Link>
                                );
                            },
                        )}
                        {/* without the negative pixel margin, there is sometimes a seam visible between the svg and the right bar*/}
                        {activeIndex >= 0 && (
                            <ActiveIndicator
                                className={`text-primary-5 absolute right-5 -mr-[1px] top-0 transform-cpu transition-transform pointer-events-none`}
                                style={{
                                    transform: `translateY(calc(1px + 2rem + ${
                                        activeIndex * 3.5
                                    }rem))`,
                                }}
                            />
                        )}
                    </div>
                </div>
                <div className="w-full flex flex-col items-center gap-3 mb-3 px-3">
                    <button
                        className="h-12 w-12 bg-primary-1 rounded-full fill-current group flex flex-col justify-center items-center transition text-neutral-400 hover:text-neutral-100"
                        onClick={handleSignOut}
                    >
                        <MdOutlineLogout aria-hidden="true" />
                    </button>
                </div>
            </div>

            {/* Mobile menu */}
            <Transition.Root show={mobileMenuOpen} as={Fragment}>
                <Dialog
                    as="div"
                    static
                    className="md:hidden"
                    open={mobileMenuOpen}
                    onClose={setMobileMenuOpen}
                >
                    <div className="fixed inset-0 z-40 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-600/75" />
                        </Transition.Child>
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <div className="relative max-w-xs w-full bg-indigo-700 pt-5 pb-4 flex-1 flex flex-col">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-1 right-0 -mr-14 p-1">
                                        <button
                                            type="button"
                                            className="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-white"
                                            onClick={() =>
                                                setMobileMenuOpen(false)
                                            }
                                        >
                                            <HiOutlineX
                                                className="h-6 w-6 text-font-light"
                                                aria-hidden="true"
                                            />
                                            <span className="sr-only">
                                                Close sidebar
                                            </span>
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="flex-shrink-0 px-4 flex items-center">
                                    <img
                                        className="h-8 w-auto"
                                        src="https://tailwindui.com/img/logos/workflow-mark.svg?color=white"
                                        alt="Workflow"
                                    />
                                </div>
                                <div className="mt-5 flex-1 h-0 px-2 overflow-y-auto">
                                    <nav className="h-full flex flex-col">
                                        <div className="space-y-1">
                                            {navigationItems.map(
                                                ({
                                                    name,
                                                    href,
                                                    icon: Icon,
                                                    id,
                                                }) => {
                                                    const isActive =
                                                        id ===
                                                        pathname.replaceAll(
                                                            '/',
                                                            '_',
                                                        );

                                                    return (
                                                        <NavLink
                                                            activeClassName="HalliHallo"
                                                            key={name}
                                                            to={href}
                                                            className={classnames(
                                                                isActive
                                                                    ? 'bg-primary-4 text-font-light'
                                                                    : 'text-blue-100 hover:bg-primary-4 hover:text-font-light',
                                                                'fill-current group py-2 px-3 rounded-md flex items-center text-sm font-medium',
                                                            )}
                                                            aria-current={
                                                                isActive
                                                                    ? 'page'
                                                                    : undefined
                                                            }
                                                        >
                                                            <Icon
                                                                className={classnames(
                                                                    isActive
                                                                        ? 'text-font-light'
                                                                        : 'text-primary-4 group-hover:text-font-light',
                                                                    'fill-current stroke-current mr-3 h-6 w-6',
                                                                )}
                                                                aria-hidden="true"
                                                            />
                                                            <span>{name}</span>
                                                        </NavLink>
                                                    );
                                                },
                                            )}
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </Transition.Child>
                        <div className="flex-shrink-0 w-14" aria-hidden="true">
                            {/* Dummy element to force sidebar to shrink to fit close icon */}
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            {subNavigation && <SubNavigationBar {...subNavigation} />}
        </Fragment>
    );
};
