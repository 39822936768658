/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    boundsToViewport,
    getSelectionBounds,
    ImageIcon,
    TextIcon,
} from '@sayari/trellis';
import { useResizeDetector } from 'react-resize-detector';
import * as Force from '@sayari/trellis/layout/force';
import {
    Props as RendererProps,
    Renderer,
} from '@sayari/trellis/bindings/react/renderer';
import { Entity } from '../types';
import { TrellisEdge, TrellisNode } from '../payload_types';
import { GraphV3MetadataType } from '../g3-deserializer';
import { CompanyDetails } from './company/CompanyDetails';
import { PersonDetails } from './person/PersonDetails';
import { NodePointerEvent } from '@sayari/trellis/renderers/webgl';

const styleNode = (
    node: TrellisNode,
    hover: boolean,
    flagCount: number,
): TrellisNode => {
    const isCompany = node.type === 'Company' || node.type === 'UserProvided';

    const isEmployeePosition = node.type === 'Person';

    const xColor = isCompany ? '#2893B0' : '#f19234';

    const style = {
        label: {
            fontSize: 12,
            wordWrap: 260,
            placement: 'bottom' as const,
            fontFamily: 'Jost',
        },
        icon: {
            type: 'imageIcon',
            url: isCompany
                ? '/corporate.svg'
                : isEmployeePosition
                ? '/individual.svg'
                : '/individual.svg',
            scale: 0.4,
        } as ImageIcon,
        color: '#FFF',
        stroke: [
            ...(hover
                ? [{ color: xColor, width: 4 }]
                : [{ color: xColor, width: 2 }]),
        ],
        badge:
            flagCount !== 0
                ? [
                      {
                          position: 45,
                          stroke: '#173548', // font-dark
                          color: '#F36966', // error-2,
                          radius: 4,
                          icon: {
                              type: 'textIcon',
                              family: 'Jost',
                              color: '#F9F9F9', // font-light
                              size: 5,
                              text: String(flagCount),
                          } as TextIcon,
                      },
                  ]
                : [],
    };

    return {
        ...node,

        radius: 10,
        style,

        label: !isCompany ? node.label?.replace(/^(Ms |Mr )/, '') : node.label,
    };
};

const EntityDetails = ({
    entity,
    onEntityClick,
    onBack,
    entityPath,
}: {
    entity: Entity | undefined;
    onEntityClick: (id: string) => void;
    onBack: () => void;
    entityPath: Entity[];
}) => {
    if (!entity) {
        return (
            <p
                style={{
                    fontSize: '16px',
                    color: '#6c757d',
                    textAlign: 'center',
                    marginTop: '50%',
                }}
            >
                Select a node to view details
            </p>
        );
    }

    const renderField = (
        label: string,
        value: string | number,
        key?: string,
    ) => (
        <div key={key} style={{ marginBottom: '15px' }}>
            <h3
                style={{
                    fontSize: '18px',
                    color: '#495057',
                    marginBottom: '5px',
                }}
            >
                {label}
            </h3>
            <p style={{ fontSize: '14px', color: '#6c757d' }}>{value}</p>
        </div>
    );

    const renderHeader = (title: string) => (
        <h2
            style={{
                fontSize: '24px',
                fontWeight: 'bold',
                color: '#343a40',
                marginBottom: '20px',
                borderBottom: '2px solid #007bff',
                paddingBottom: '10px',
            }}
        >
            {title}
        </h2>
    );

    return (
        <>
            {entityPath.length > 1 && (
                <button
                    onClick={onBack}
                    style={{
                        marginBottom: '20px',
                        padding: '5px 10px',
                        backgroundColor: '#007bff',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                    }}
                >
                    Back
                </button>
            )}
            {entity.type === 'Person' && (
                <PersonDetails
                    entity={entity}
                    renderField={renderField}
                    onEntityClick={onEntityClick}
                    renderHeader={renderHeader}
                />
            )}
            {entity.type === 'Company' && (
                <CompanyDetails
                    entity={entity}
                    renderField={renderField}
                    onEntityClick={onEntityClick}
                    renderHeader={renderHeader}
                />
            )}
        </>
    );
};

const force = Force.Layout();

const GraphRenderer = (props: RendererProps) => <Renderer {...props} />;

function getFlagCount(node: TrellisNode): number {
    const cocaFlagCount = (node?.meta?.get(GraphV3MetadataType.CoCaFlagCount) ??
        0) as number;
    const wocoFlagCount = (node?.meta?.get(GraphV3MetadataType.WoCoFlagCount) ??
        0) as number;
    return cocaFlagCount + wocoFlagCount;
}

export const EntitiesContext = createContext<Map<string, Entity>>(new Map());

export const Graph: React.FC<{
    data: {
        nodes: TrellisNode[];
        edges: TrellisEdge[];
        entities: Map<string, Entity>;
    };
    onWidthChange?: (width: number) => void;
}> = ({ data: { nodes, edges, entities }, onWidthChange = () => null }) => {
    const {
        width,
        height,
        ref: target_ref,
    } = useResizeDetector<HTMLDivElement>({
        onResize: (payload) => onWidthChange(payload.width ?? 0),
    });
    const current_width = useRef<number>();
    const current_height = useRef<number>();

    const [graph, set_graph] = useState({
        nodes: [] as TrellisNode[],
        edges: [] as TrellisEdge[],
        x: 0,
        y: 0,
        zoom: 1,
        hover_node: undefined as string | undefined,
        hover_edge: undefined as string | undefined,
        entity_path: [] as Entity[],
    });

    useEffect(() => {
        current_width.current = width;
        current_height.current = height;

        force({ nodes, edges }).then(
            ({
                nodes,
                edges,
            }: {
                nodes: TrellisNode[];
                edges: TrellisEdge[];
            }) => {
                const { x, y, zoom } = boundsToViewport(
                    getSelectionBounds(nodes, 60),
                    {
                        width: current_width.current
                            ? current_width.current - 250
                            : 0,
                        height: current_height.current ?? 0,
                    },
                );

                set_graph((graph) => ({
                    ...graph,

                    nodes,
                    edges,
                    x,
                    y,
                    zoom,
                }));
            },
        );
    }, [width, height, nodes, edges]);

    const on_node_drag = useCallback(
        ({
            nodeX: node_x,
            nodeY: node_y,
            target: { id },
        }: {
            nodeX: number;
            nodeY: number;
            target: { id: string };
        }) => {
            set_graph((graph) => ({
                ...graph,
                nodes: graph.nodes.map((node) =>
                    node.id === id ? { ...node, x: node_x, y: node_y } : node,
                ),
            }));
        },
        [],
    );

    const on_viewport_drag = useCallback(
        ({
            viewportX: x,
            viewportY: y,
        }: {
            viewportX: number;
            viewportY: number;
        }) => {
            set_graph((graph) => ({ ...graph, x, y }));
        },
        [],
    );

    const on_viewport_wheel = useCallback(
        ({
            viewportX: x,
            viewportY: y,
            viewportZoom: zoom,
        }: {
            viewportX: number;
            viewportY: number;
            viewportZoom: number;
        }) => {
            set_graph((graph) => ({ ...graph, x, y, zoom }));
        },
        [],
    );

    const on_node_pointer_enter = useCallback(
        ({ target: { id } }: { target: { id: string } }) => {
            set_graph((graph) => ({ ...graph, hover_node: id }));
        },
        [],
    );

    const on_node_pointer_leave = useCallback(() => {
        set_graph((graph) => ({ ...graph, hover_node: undefined }));
    }, []);

    const on_edge_pointer_enter = useCallback(
        ({ target: { id } }: { target: { id: string } }) => {
            set_graph((graph) => ({ ...graph, hover_edge: id }));
        },
        [],
    );

    const on_edge_pointer_leave = useCallback(() => {
        set_graph((graph) => ({ ...graph, hover_edge: undefined }));
    }, []);

    const on_node_click = useCallback(
        (e: NodePointerEvent) => {
            console.log('e', e);
            console.log('x', e.x);
            console.log('y', e.y);
            console.log('clientX', e.clientX);
            console.log('clientY', e.clientY);
            console.log('graph', graph, graph.x, graph.y);
            const { id, x = 0, y = 0 } = e.target;
            const entity = entities.get(id);
            console.log('click', id, entity);
            if (entity) {
                set_graph((graph) => {
                    console.log('graph', graph);
                    return { ...graph, entity_path: [entity], x: -x, y: -y };
                });
            }
        },
        [entities],
    );

    const on_node_double_click = useCallback(
        ({ target: { id } }: { target: { id: string } }) => {
            const entity = entities.get(id);
            console.log('doubleClick', id, entity);
            console.log('graph', graph);
            if (entity) {
                set_graph((graph) => ({
                    ...graph,
                    entity_path: [entity],
                    edges: graph.edges.filter(
                        (edge) => edge.source === id || edge.target === id,
                    ),
                }));
            }
        },
        [entities],
    );

    const on_entity_click = useCallback(
        (id: string) => {
            const entity = entities.get(id);
            if (entity) {
                set_graph((graph) => ({
                    ...graph,
                    entity_path: [...graph.entity_path, entity],
                }));
            }
        },
        [entities],
    );

    const on_back = useCallback(() => {
        set_graph((graph) => ({
            ...graph,
            entity_path: graph.entity_path.slice(0, -1),
        }));
    }, []);

    const styled_nodes = useMemo(
        () =>
            graph.nodes.map((node) => {
                return styleNode(
                    node,
                    graph.hover_node === node.id,
                    getFlagCount(node),
                );
            }),
        [graph.nodes, graph.hover_node],
    );

    const styled_edges = useMemo(
        () =>
            graph.edges.map((edge) => ({
                ...edge,
                style: {
                    label: {
                        fontFamily: 'OpenSans',
                        fontSize: 8,
                    },
                    ...(edge.id === graph.hover_edge
                        ? {
                              width: 2,
                              arrow: 'forward' as const,
                              stroke: '#000',
                          }
                        : { width: 1, arrow: 'forward' as const }),
                },
            })),
        [graph.edges, graph.hover_edge],
    );

    const selected_entity =
        graph.entity_path.length > 0
            ? graph.entity_path[graph.entity_path.length - 1]
            : undefined;

    if (width === undefined || height === undefined) {
        return (
            <div
                ref={target_ref}
                style={{
                    position: 'relative',
                    overflow: 'hidden',
                }}
            >
                <span />
            </div>
        );
    }

    return (
        <div ref={target_ref} className="relative overflow-hidden flex h-full">
            <GraphRenderer
                width={width - 300}
                height={height}
                nodes={styled_nodes}
                edges={styled_edges}
                x={graph.x}
                y={graph.y}
                zoom={graph.zoom}
                onNodeDrag={on_node_drag}
                onNodePointerEnter={on_node_pointer_enter}
                onNodePointerLeave={on_node_pointer_leave}
                onEdgePointerEnter={on_edge_pointer_enter}
                onEdgePointerLeave={on_edge_pointer_leave}
                onViewportDrag={on_viewport_drag}
                onViewportWheel={on_viewport_wheel}
                onNodeClick={on_node_click}
                onNodeDoubleClick={on_node_double_click}
            />
            <div className="w-75 h-full bg-neutral-100 p-6 overflow-y-auto shadow-lg drop-shadow-lg">
                <EntitiesContext.Provider value={entities}>
                    <EntityDetails
                        entity={selected_entity}
                        onEntityClick={on_entity_click}
                        onBack={on_back}
                        entityPath={graph.entity_path}
                    />
                </EntitiesContext.Provider>
            </div>
        </div>
    );
};
