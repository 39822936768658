import {
    Membership,
    MembershipFunctionRole,
    MembershipFunctionRoleCode,
    Person,
} from '../../types';
import React from 'react';
import { formatDate } from '../../logic/utils';
import { LinkedEntityListing } from '../LinkedEntityListing';

// Updated Memberships component
export const Memberships: React.FC<{
    entity: Person;
    onEntityClick: (id: string) => void;
}> = ({ entity, onEntityClick }) => {
    if (!entity.memberships?.length) {
        return null;
    }

    const membershipMap = new Map<
        string,
        {
            company_name: string;
            memberships: Omit<Membership, 'company_id' | 'company_name'>[];
            mostRecentStartDate: string | null;
            markInactive: boolean;
        }
    >();

    const processMembership = (membership: Membership) => {
        if (!membershipMap.has(membership.company_id)) {
            membershipMap.set(membership.company_id, {
                company_name: membership.company_name,
                memberships: [],
                mostRecentStartDate: null,
                markInactive: true,
            });
        }

        const companyData = membershipMap.get(membership.company_id)!;

        // Check for duplicate memberships
        const existingMembership = companyData.memberships.find(
            (m) =>
                !m.beginning_nomination_date &&
                !m.end_expiration_date &&
                m.functions.every((f) =>
                    membership.functions.some(
                        (mf) => mf.role === f.role && mf.label === f.label,
                    ),
                ),
        );

        if (existingMembership) {
            if (
                membership.status === 'Current' &&
                existingMembership.status === 'Previous'
            ) {
                // Replace the existing 'Previous' membership with the 'Current' one
                const index =
                    companyData.memberships.indexOf(existingMembership);
                companyData.memberships[index] = {
                    ...membership,
                    company_id: undefined,
                    company_name: undefined,
                } as Omit<Membership, 'company_id' | 'company_name'>;
            }
            // If the new membership is 'Previous' or both are the same status, we keep the existing one
            return;
        }

        companyData.memberships.push({
            ...membership,
            company_id: undefined,
            company_name: undefined,
        } as Omit<Membership, 'company_id' | 'company_name'>);

        if (membership.status !== 'Previous') {
            companyData.markInactive = false;
        }

        if (membership.beginning_nomination_date) {
            if (
                !companyData.mostRecentStartDate ||
                membership.beginning_nomination_date >
                    companyData.mostRecentStartDate
            ) {
                companyData.mostRecentStartDate =
                    membership.beginning_nomination_date;
            }
        }
    };

    for (const membership of entity.memberships) {
        processMembership(membership);
    }

    const sortedCompanies = Array.from(membershipMap).sort(([, a], [, b]) => {
        if (a.markInactive && !b.markInactive) {
            return 1;
        }
        if (!a.markInactive && b.markInactive) {
            return -1;
        }
        if (a.mostRecentStartDate && b.mostRecentStartDate) {
            return b.mostRecentStartDate.localeCompare(a.mostRecentStartDate);
        }
        if (a.mostRecentStartDate) {
            return -1;
        }
        if (b.mostRecentStartDate) {
            return 1;
        }
        return 0;
    });

    const formattedMemberships: [
        string,
        {
            name: string;
            items: {
                id: string;
                title: string;
                details: { label: string; value: string }[];
            }[];
            markInactive: boolean;
        },
    ][] = sortedCompanies.map(
        ([company_id, { company_name, memberships, markInactive }]) => [
            company_id,
            {
                name: company_name,
                items: memberships.map((membership, index) => ({
                    id: `${company_id}-${index}`,
                    title: membership.functions
                        .map(
                            (func) =>
                                `${func.role} ${
                                    func.label ? `(${func.label?.trim()})` : ''
                                }`,
                        )
                        .join(', '),
                    details: [
                        ...(membership.beginning_nomination_date
                            ? [
                                  {
                                      label: 'From',
                                      value: formatDate(
                                          membership.beginning_nomination_date,
                                      ),
                                  },
                              ]
                            : []),
                        ...(membership.end_expiration_date
                            ? [
                                  {
                                      label: 'Until',
                                      value: formatDate(
                                          membership.end_expiration_date,
                                      ),
                                  },
                              ]
                            : []),
                        ...(membership.work_email
                            ? [{ label: 'Email', value: membership.work_email }]
                            : []),
                        ...(membership.work_phone
                            ? [{ label: 'Phone', value: membership.work_phone }]
                            : []),
                        ...(membership.work_city
                            ? [
                                  {
                                      label: 'Location',
                                      value: [
                                          membership.work_city,
                                          membership.work_province_or_state,
                                          membership.work_country,
                                      ]
                                          .filter(Boolean)
                                          .join(', '),
                                  },
                              ]
                            : []),
                    ],
                })),
                flags: Array.from(
                    new Set(
                        memberships
                            .map((ms) => ms.functions.map((f) => f.role))
                            .flat(),
                    ),
                )
                    .map((role) => {
                        switch (role) {
                            case MembershipFunctionRole.Management:
                                return MembershipFunctionRoleCode.Management;

                            case MembershipFunctionRole.Board:
                                return MembershipFunctionRoleCode.Board;

                            case MembershipFunctionRole.Shareholder:
                                return MembershipFunctionRoleCode.Shareholder;

                            case MembershipFunctionRole.Advisor:
                                return MembershipFunctionRoleCode.Advisor;

                            case MembershipFunctionRole.Staff:
                                return MembershipFunctionRoleCode.Staff;

                            default:
                                return null;
                        }
                    })
                    .filter((role) => role !== null)
                    .map((role) => ({ displayText: role })),
                markInactive,
            },
        ],
    );

    return (
        <LinkedEntityListing
            label="Memberships"
            linkedEntities={formattedMemberships}
            onEntityClick={onEntityClick}
            formatDate={formatDate}
        />
    );
};
