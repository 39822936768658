import { CoCaEntity, Entity } from '../types';
import { LinkedEntityListing } from './LinkedEntityListing';
import React from 'react';

function isDetail(detail: unknown): detail is { label: string; value: string } {
    if (typeof detail === 'object' && detail !== null) {
        return 'label' in detail && 'value' in detail;
    }
    return false;
}

export const ComplianceFlags: React.FC<{
    entity: Entity;
    onEntityClick?: (id: string) => void;
}> = ({ entity, onEntityClick = () => null }) => {
    if (!entity.coca_entities || entity.coca_entities.length === 0) {
        return null;
    }

    const linkedEntities: [
        string,
        {
            name: string;
            items: {
                id: string;
                title: string;
                details: { label: string; value: string }[];
            }[];
            flags?: { displayText: string }[];
            markInactive: boolean;
        },
    ][] = entity.coca_entities.map((cocaEntity: CoCaEntity) => [
        cocaEntity.external_id,
        {
            name: [
                cocaEntity.category,
                [
                    typeof cocaEntity.sub_category === 'object'
                        ? cocaEntity.sub_category.value
                        : cocaEntity.sub_category,
                ]
                    .filter((x) => x)
                    .map((x) => `(${x})` as const),
            ].join(' '),
            items: [
                {
                    id: cocaEntity.external_id,
                    title: cocaEntity.entity_type,
                    details: [
                        { label: 'External ID', value: cocaEntity.external_id },
                        { label: 'Country', value: cocaEntity.country },
                        {
                            label: 'Position or CAS',
                            value: cocaEntity.position_or_cas,
                        },
                        {
                            label: 'Sanction List Source',
                            value: cocaEntity.sanctionlist_source,
                        },
                        {
                            label: 'Sanction List Country',
                            value: cocaEntity.sanctionlist_country_iso_code,
                        },
                        {
                            label: 'AKA Names',
                            value: cocaEntity.aka_names
                                ? cocaEntity.aka_names.join(', ')
                                : '',
                        },
                        {
                            label: 'Entity Level',
                            value: cocaEntity.entity_level,
                        },
                        { label: 'Entity Date', value: cocaEntity.entity_date },
                    ].filter(isDetail),
                },
                ...(cocaEntity.individual
                    ? [
                          {
                              id: `${cocaEntity.external_id}-individual`,
                              title: 'Individual Details',
                              details: [
                                  cocaEntity.individual.primary_name && {
                                      label: 'Primary Name',
                                      value: cocaEntity.individual.primary_name,
                                  },
                                  cocaEntity.individual.function && {
                                      label: 'Function',
                                      value: cocaEntity.individual.function,
                                  },
                                  cocaEntity.individual.birthdate && {
                                      label: 'Birth Date',
                                      value: cocaEntity.individual.birthdate
                                          ? cocaEntity.individual.birthdate.join(
                                                ', ',
                                            )
                                          : '',
                                  },
                                  cocaEntity.individual.birthplace && {
                                      label: 'Birth Place',
                                      value: cocaEntity.individual.birthplace,
                                  },
                                  {
                                      label: 'Gender',
                                      value: cocaEntity.individual.gender,
                                  },
                              ].filter(isDetail),
                          },
                      ]
                    : []),
                ...(cocaEntity.addresses
                    ? cocaEntity.addresses.length > 0
                        ? [
                              {
                                  id: `${cocaEntity.external_id}-addresses`,
                                  title: 'Addresses',
                                  details: cocaEntity.addresses
                                      .map((address, index) => ({
                                          label: `${index + 1}`,
                                          value: `${address.address}, ${address.city}, ${address.country_label}`,
                                      }))
                                      .filter(isDetail),
                              },
                          ]
                        : []
                    : []),
            ],
            flags: [{ displayText: cocaEntity.entity_type }],
            markInactive: false,
        },
    ]);

    return (
        <LinkedEntityListing
            label="Compliance Flags"
            linkedEntities={linkedEntities}
            onEntityClick={onEntityClick}
            formatDate={(dateString) =>
                new Date(dateString).toLocaleDateString()
            }
        />
    );
};
