import React from 'react';
import { LinkedEntity } from '../types';

export const LinkedEntityListing = ({
    label,
    linkedEntities,
    onEntityClick = () => null,
}: {
    label: string;
    linkedEntities: Array<LinkedEntity>;
    onEntityClick?: (id: string) => void;
    formatDate: (dateString: string) => string;
}): JSX.Element | null => {
    const [expandedEntities, setExpandedEntities] = React.useState<Set<string>>(
        new Set(linkedEntities.map(([id]) => id)),
    );

    if (linkedEntities.length === 0) {
        return null;
    }

    const toggleEntity = (id: string) => {
        setExpandedEntities((prev) => {
            const newSet = new Set(prev);
            if (newSet.has(id)) {
                newSet.delete(id);
            } else {
                newSet.add(id);
            }
            return newSet;
        });
    };

    return (
        <div style={{ marginBottom: '15px' }}>
            <h3
                style={{
                    fontSize: '18px',
                    color: '#495057',
                    marginBottom: '5px',
                }}
            >
                {label}
            </h3>
            {linkedEntities.map(
                ([id, { name, items, flags, markInactive }]) => (
                    <div
                        key={id}
                        style={{
                            marginBottom: '10px',
                            padding: '10px',
                            backgroundColor: '#e9ecef',
                            position: 'relative',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <p
                                style={{
                                    fontSize: '14px',
                                    color: markInactive ? '#8a8a8a' : '#495057',
                                    fontWeight: 'bold',
                                    fontStyle: markInactive
                                        ? 'italic'
                                        : 'normal',
                                    cursor: 'pointer',
                                    margin: 0,
                                }}
                                onClick={() => onEntityClick(id)}
                            >
                                {name}
                            </p>
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleEntity(id);
                                }}
                                style={{
                                    width: '16px',
                                    height: '16px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    background: 'none',
                                    border: '1px solid #ced4da',
                                    cursor: 'pointer',
                                    padding: 0,
                                    transform: expandedEntities.has(id)
                                        ? 'rotate(0deg)'
                                        : 'rotate(180deg)',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                            >
                                ▲
                            </button>
                        </div>
                        {flags && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'left',
                                    alignItems: 'center',
                                }}
                            >
                                {flags.map((flag, i) => (
                                    <p
                                        key={i}
                                        style={{
                                            fontSize: '9px',
                                            color: markInactive
                                                ? '#8a8a8a'
                                                : '#495057',
                                            fontWeight: 'bold',
                                            fontStyle: markInactive
                                                ? 'italic'
                                                : 'normal',
                                            cursor: 'pointer',
                                            margin: '0 2px 0 0',
                                        }}
                                        onClick={() => onEntityClick(id)}
                                    >
                                        {flag.displayText}
                                    </p>
                                ))}
                            </div>
                        )}
                        {expandedEntities.has(id) && (
                            <ul
                                style={{
                                    margin: '10px 0 0 20px',
                                    padding: 0,
                                    maxHeight: '1000px',
                                    transition: 'max-height 0.5s ease-in-out',
                                }}
                            >
                                {items
                                    .filter((item) => item)
                                    .map((item) => (
                                        <li key={item.id}>
                                            <p
                                                style={{
                                                    fontSize: '12px',
                                                    color: markInactive
                                                        ? '#8a8a8a'
                                                        : '#6c757d',
                                                    fontStyle: markInactive
                                                        ? 'italic'
                                                        : 'normal',
                                                    marginBottom: '2px',
                                                }}
                                            >
                                                {item.title}
                                            </p>
                                            {item.details
                                                .filter((detail) => detail)
                                                .map((detail, index) => (
                                                    <p
                                                        key={index}
                                                        style={{
                                                            fontSize: '11px',
                                                            color: '#6c757d',
                                                            margin: '0',
                                                        }}
                                                    >
                                                        {detail.label}:{' '}
                                                        {detail.value}
                                                    </p>
                                                ))}
                                        </li>
                                    ))}
                            </ul>
                        )}
                    </div>
                ),
            )}
        </div>
    );
};
