import { Card, TooltipContentV2, TooltipTriggerV2, TooltipV2 } from '_atoms';
import { RedFlagItem, SuspicionByCategory } from '_types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { UseFilters } from './useFilters';
import { SuspicionRiskLevel } from '_enums';
import { SuspicionBadge } from './SuspicionBadge';
import { RedFlagIcon } from './RedFlagIcon';
import { ReactComponent as ExclamationIcon } from '_assets/icons/exclamation.svg';
import { ReactComponent as CheckIcon } from '_assets/icons/check.svg';
import { ReactComponent as CrossIcon } from '_assets/icons/cross.svg';

interface SuspicionRiskProps {
    risk: SuspicionRiskLevel;
    suspicions: SuspicionByCategory[];
    setFilters: UseFilters['setFilters'];
    filters: UseFilters['filters'];
    redFlags?: RedFlagItem[];
}

const riskToColor = {
    high: 'error-1',
    medium: 'warning-1',
    low: 'success-1',
};

const separatorClassName =
    'w-1 h-1 rounded-full bg-neutral-500 inline-block mx-2';

export type UniqueRedFlag = RedFlagItem & {
    count: number;
};

export const deduplicateRedFlags = (
    redFlags: RedFlagItem[],
): UniqueRedFlag[] => {
    const uniqueRedFlagMap = new Map<string, UniqueRedFlag>();

    redFlags.forEach((redFlag) => {
        const currentRedFlag = uniqueRedFlagMap.get(redFlag.type);

        uniqueRedFlagMap.set(redFlag.type, {
            ...(currentRedFlag ?? redFlag),
            count: (currentRedFlag?.count ?? 0) + 1,
        });
    });

    return Array.from(uniqueRedFlagMap.values()).sort((a, b) =>
        a.type.localeCompare(b.type),
    );
};

export const SuspicionRisk = (
    props: SuspicionRiskProps,
): React.ReactElement => {
    const { risk, suspicions, redFlags, setFilters, filters } = props;

    const { t } = useTranslation();

    const deduplicatedRedFlags = useMemo(
        () => deduplicateRedFlags(redFlags ?? []),
        [redFlags],
    );

    const color = riskToColor[risk];

    const total = useMemo(
        () =>
            suspicions.reduce((total, suspicion) => total + suspicion.total, 0),
        [suspicions],
    );

    const confirmedTotal = useMemo(
        () =>
            suspicions.reduce(
                (total, suspicion) => total + suspicion.confirmedTotal,
                0,
            ),
        [suspicions],
    );

    const pendingTotal = useMemo(
        () =>
            suspicions.reduce(
                (total, suspicion) => total + suspicion.pendingTotal,
                0,
            ),
        [suspicions],
    );

    const removedTotal = useMemo(
        () =>
            suspicions.reduce(
                (total, suspicion) => total + suspicion.removedTotal,
                0,
            ),
        [suspicions],
    );

    const sortedSuspicions = suspicions.sort((a, b) => {
        const totalDifference = b.total - a.total;
        if (totalDifference !== 0) {
            return totalDifference;
        }

        return (a.label ?? '').localeCompare(b.label ?? '');
    });

    const totalRedFlags = deduplicatedRedFlags.reduce(
        (acc, next) => acc + next.count,
        0,
    );

    return (
        <div>
            <div
                className={`text-${color} font-semibold mb-2 text-xs font-jost flex items-center`}
            >
                <span>{t(`riskAnalysis.suspicionRisk.${risk}.title`)}</span>
                <span className={separatorClassName} />
                <span className="text-neutral-500 flex items-center">
                    {deduplicatedRedFlags.length > 0 && (
                        <>
                            <RedFlagIcon />
                            <span className="ml-1">{totalRedFlags}</span>
                            <label className="font-light ml-1">
                                {t('riskAnalysis.suspicionRisk.totalRedFlags')}
                            </label>
                            <span className={separatorClassName} />
                        </>
                    )}
                    {total}
                    <label className="font-light mr-2 ml-1">
                        {t('riskAnalysis.suspicionRisk.total')}
                    </label>
                    (
                    <CheckIcon className="w-3 h-3" />
                    <span className="ml-1 font-bold">{confirmedTotal}</span>
                    <label className="font-bold ml-1">
                        {t('riskAnalysis.suspicionRisk.totalConfirmed')}
                    </label>
                    <span className="mx-1">/</span>
                    <ExclamationIcon className="w-3 h-3" />
                    <span className="ml-1 font-bold">{pendingTotal}</span>
                    <label className="font-bold ml-1">
                        {t('riskAnalysis.suspicionRisk.totalPending')}
                    </label>
                    <span className="mx-1">/</span>
                    <CrossIcon className="w-3 h-3" />
                    <span className="ml-1 font-bold">{removedTotal}</span>
                    <label className="font-bold ml-1">
                        {t('riskAnalysis.suspicionRisk.totalRemoved')}
                    </label>
                    )
                </span>
            </div>
            <Card
                className={`border-${color} border-l-8 p-6 flex items-center justify-between gap-6`}
            >
                <div className="flex flex-wrap gap-3 flex-1">
                    {deduplicatedRedFlags.map((redFlag) => {
                        const isRedFlagActive =
                            filters.redFlag === redFlag.type &&
                            filters.risk === risk;
                        return (
                            <SuspicionBadge
                                key={redFlag.type}
                                name={redFlag.type}
                                onClick={() => {
                                    setFilters({
                                        selectedSuspicionId: undefined,
                                        risk,
                                        redFlag: redFlag.type,
                                    });
                                }}
                                isSelected={isRedFlagActive}
                                total={redFlag.count}
                                isRedFlag={true}
                            />
                        );
                    })}
                    {sortedSuspicions.map((suspicion) => {
                        const isSuspicionActive =
                            filters.selectedSuspicionId === suspicion.id &&
                            filters.risk === risk;

                        return (
                            <SuspicionBadge
                                key={suspicion.id}
                                className={
                                    isSuspicionActive
                                        ? 'pointer-events-none'
                                        : ''
                                }
                                isSelected={isSuspicionActive}
                                onClick={() => {
                                    setFilters({
                                        selectedSuspicionId: suspicion.id,
                                        risk,
                                        redFlag: undefined,
                                    });
                                }}
                                name={t(
                                    `suspicionKeywords.${suspicion.label}`,
                                    {
                                        defaultValue: suspicion.name,
                                    },
                                )}
                                involvement={
                                    suspicion.involvement
                                        ? t(
                                              `suspicionItemInvolvement.${suspicion.involvement}`,
                                          )
                                        : undefined
                                }
                                total={suspicion.total}
                            />
                        );
                    })}
                </div>
                <TooltipV2 openDelay={500} placement="top-start">
                    <TooltipContentV2 className="max-w-72 z-20" type="primary">
                        {t(`riskAnalysis.suspicionRisk.${risk}.tooltip`)}
                    </TooltipContentV2>
                    <TooltipTriggerV2 asChild={true}>
                        <div className="text-primary-4">
                            <IoMdInformationCircleOutline />
                        </div>
                    </TooltipTriggerV2>
                </TooltipV2>
            </Card>
        </div>
    );
};
