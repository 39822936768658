import React, { useCallback } from 'react';
import {
    cvDisplayOrderLeftTop,
    cvDisplayOrderRight,
    dynamicKeywordSectionKeys,
    FormSchema,
    FormType,
} from './form.interface';
import { Card } from '_atoms';
import { DynamicFormFieldDetails } from './DynamicFormFieldDetails';
import { SubmitHandler } from 'react-hook-form';

const sectionHasData = (data: unknown | unknown[]): boolean => {
    if (!data) {
        return false;
    }
    if (typeof data === 'object') {
        if (Array.isArray(data)) {
            return !!data.length;
        }
        // Checking the top-level properties of a section is enough
        return Object.values(data).some((item) => !!item);
    }
    // form configs are either Objects or arrays, if none of the above fail, we can safely assume the section has data
    return true;
};

export interface TargetSummaryProps {
    data: Partial<FormSchema>;
    cvImages: string[];
    onUpdate: SubmitHandler<Partial<FormSchema>>;
    editHandler: (key: FormType, index?: number) => void;
}

export const TargetSummary: React.FC<TargetSummaryProps> = ({
    data,
    cvImages = [],
    editHandler,
    onUpdate,
}) => {
    const deleteHandler = useCallback(
        (key: FormType, index?: number) => {
            /*
             * Deep Clone form data for mutation...
             * If we run into issues with deletion down the line, we need to do the deep clone in a more robust way.
             * It will work fine right now
             * since we do not have date types and other types that do not play well with stringify/parse
             */
            const newData: Partial<FormSchema> = JSON.parse(
                JSON.stringify(data),
            );

            // Deletion logic for keys with an array-type
            if (
                newData[key] &&
                index !== undefined &&
                Array.isArray(newData[key])
            ) {
                const array = newData[key] as unknown[];
                if (index >= 0 && index < array.length) {
                    array.splice(index, 1);
                    if (array.length === 0) {
                        delete newData[key];
                    }
                }
            } else {
                delete newData[key];
            }
            onUpdate(newData);
        },
        [data, onUpdate],
    );

    const renderContent = (key: FormType) => {
        const sectionValue = data[key];

        if (!sectionValue || !sectionHasData(sectionValue)) {
            return null;
        }

        return (
            <DynamicFormFieldDetails
                data={sectionValue}
                sectionKey={key}
                onEditData={editHandler}
                onDeleteData={deleteHandler}
                key={key}
            />
        );
    };

    const leftSide = (
        <div className="flex flex-col bg-primary-2 text-font-light p-8">
            {renderContent('personalDetails')}
            {cvImages.length > 0 && (
                <div className="bg-gray-200 h-40 w-40 rounded-full relative text-center">
                    <img
                        src={
                            cvImages[0].startsWith('http')
                                ? cvImages[0]
                                : `data:image/jpeg;base64,${cvImages[0]}`
                        }
                        alt="Profile"
                        className="rounded-full absolute inset-0 w-full h-full object-cover"
                    />
                </div>
            )}
            {cvDisplayOrderLeftTop.map(renderContent)}
            <div className="mt-6">
                {dynamicKeywordSectionKeys.map(renderContent)}
            </div>
        </div>
    );

    return (
        <Card className="w-full overflow-y-auto flex-grow grid grid-cols-[2fr_3fr]">
            {leftSide}
            {/* right side */}
            <div className="flex flex-col p-8 gap-4">
                {cvDisplayOrderRight.map(renderContent)}
            </div>
        </Card>
    );
};
