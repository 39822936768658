import React, {
    useState,
    useEffect,
    useContext,
    createContext,
    useRef,
} from 'react';
import {
    IoIosInformationCircleOutline,
    IoIosAlert,
    IoMdClose,
} from 'react-icons/io';

interface ToastOptions {
    id: string;
    ms?: number;
    text: React.ReactNode;
    style: 'info' | 'confirmation' | 'error' | 'warning';
}

const ToastContext = createContext<(toast: Omit<ToastOptions, 'id'>) => void>(
    () => {
        throw new Error('useToast must be used within a ToastProvider');
    },
);

export const useToast = (): ((toast: Omit<ToastOptions, 'id'>) => void) => {
    return useContext(ToastContext);
};

const Toast: React.FC<ToastOptions & { onRemove: () => void }> = ({
    ms = 0,
    text,
    style,
    onRemove,
}) => {
    const [visible, setVisible] = useState(true);
    const toastRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ms === 0) return;
        const timer = setTimeout(() => {
            setVisible(false);
        }, ms);
        return () => clearTimeout(timer);
    }, [ms]);

    const handleTransitionEnd = () => {
        if (!visible) {
            onRemove();
        }
    };

    const styleColors: Record<ToastOptions['style'], string> = {
        info: 'bg-blue-500 text-white',
        confirmation: 'bg-green-500 text-white',
        error: 'bg-red-500 text-white',
        warning: 'bg-yellow-500 text-black',
    };

    const styleIcons = {
        info: <IoIosInformationCircleOutline />,
        confirmation: <IoIosInformationCircleOutline />,
        error: <IoIosAlert />,
        warning: <IoIosAlert />,
    };

    return (
        <div
            ref={toastRef}
            className={`rounded-md p-[2px] pl-2 flex flex-row items-center justify-center shadow-md transition-all duration-300 ease-in-out relative overflow-hidden ${
                visible ? 'opacity-100' : 'opacity-0'
            } ${styleColors[style]}`}
            style={{ height: visible ? toastRef.current?.offsetHeight : 0 }}
            onTransitionEnd={handleTransitionEnd}
        >
            <div className="absolute right-1 top-1">
                <IoMdClose
                    className="cursor-pointer text-primary-1"
                    onClick={() => setVisible(false)}
                    size={10}
                />
            </div>
            <div className="mr-2">{styleIcons[style]}</div>
            <div className="px-4 py-2 bg-white rounded text-primary-1 text-xs">
                {text}
            </div>
        </div>
    );
};

export const ToastProvider: React.FC = ({ children }) => {
    const [toasts, setToasts] = useState<ToastOptions[]>([]);

    const addToast = (toast: Omit<ToastOptions, 'id'>) => {
        const id = Math.random().toString(36).substr(2, 9);
        setToasts((prevToasts) => [...prevToasts, { ...toast, id }]);
    };

    const removeToast = (id: string) => {
        setToasts((prevToasts) =>
            prevToasts.filter((toast) => toast.id !== id),
        );
    };

    return (
        <ToastContext.Provider value={addToast}>
            {children}
            <div className="fixed bottom-5 right-5 flex flex-col-reverse gap-2.5">
                {toasts.map((toast) => (
                    <Toast
                        key={toast.id}
                        {...toast}
                        onRemove={() => removeToast(toast.id)}
                    />
                ))}
            </div>
        </ToastContext.Provider>
    );
};
